<template lang="html">
<v-container>
  <v-card icon="mdi-account" >
		<v-card-text>
		<h1 class="display-2 my-2">Form Lampiran Angka Kredit</h1>
		<hr>
		<h3 class="my-2">Rencana Kinerja:</h3>
		<label>{{dataPegawai.rencana_kinerja}}</label>
		</v-card-text>
    <v-row>
      <v-col align="center">
				<v-btn color="info" class="ma-1" @click="addItem()">
					<v-icon  title="detail">
						mdi-plus
					</v-icon>
					Tambah Baru
				</v-btn>
			</v-col>
		</v-row>
		<br>
		<!-- <v-spacer></v-spacer> -->
		<v-data-table
			:headers="headersAngkaKredit"
			class="elevation-1"
		>
		<template slot="body.append">
			<tr v-for="item in rowDataAngkaKredit" v-bind:key="item">
				<td scope="row">{{ item.no }}</td>
				<td>
					<v-textarea v-model="item.butir" label="Butir" outlined ></v-textarea>
				</td>
				<td>
					<v-textarea v-model="item.output" label="Output" outlined ></v-textarea>
				</td>
				<td>
					<v-textarea v-model="item.angka_kredit" label="Angka Kredit" outlined ></v-textarea>
				</td>
				<td>
					<v-btn color="error" @click="removeItem(item)">
						<v-icon  title="detail">mdi-delete</v-icon>
						Hapus Baris</v-btn>
				</td>
			</tr>
		</template>
		<template v-slot:no-data>
			<label></label>
		</template>
		</v-data-table>
		<v-card-actions class="d-flex justify-center">
      <v-btn @click="closeEvent()" color="warning">tutup</v-btn>
      <v-btn @click="save()" color="primary">Simpan</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import SKPService from '@/services/SKPService'

  export default {
		components:{
		},
    props:{
			dataPegawai:Array,
      rowDataAngkaKredit:Array
		},
    data() {
        return {
					items: {},
					iteration: 1,
					headersAngkaKredit:[
						{text:'No', class:'header-index-profile'},
						{text:'Butir', class:'header-index-profile'},
						{text:'Output', class:'header-index-profile'},
						{text:'Angka Kredit', class:'header-index-profile'},
						{value:'aksi', sortable:false, class:'header-index-profile'},
					],
					// rowData: [],
        };
    },
		watch:{
		},
    methods: {
			addItem(){
				this.no = this.rowDataAngkaKredit.length
				this.no += 1
				var my_object = {
					id:this.id,
					no:this.no,
					butir:this.butir,
					output:this.output,
					angka_kredit: this.angka_kredit,
					nip: this.nip18
				};
				this.rowDataAngkaKredit.push(my_object)
			},
			removeItem(item) {
				this.no -= 1
				for (let i = 0; i < this.rowDataAngkaKredit.length; i++) {
					const el = this.rowDataAngkaKredit[i];
					if(el.no == item.no){
						this.rowDataAngkaKredit.splice(i, 1)
					}
				}
				// re-indexing
				for (let i = 0; i < this.rowDataAngkaKredit.length; i++) {
					this.rowDataAngkaKredit[i].no = i+1
				}
				console.log(this.rowDataAngkaKredit)
			},
			closeEvent(){
				this.$emit('onClose',false)
			},
			save(){
        if(this.rowDataAngkaKredit.length == 0){
          store.commit('snackbar/setSnack',{message: 'Anda belum menambahkan dan mengisi lampiran angka kredit. Silahkan isi dahulu!', color:'error'})
          return false
        }
        this.items.id = this.dataPegawai.id
        this.items.lampiran_angka_kredit = JSON.stringify(this.rowDataAngkaKredit)
				SKPService.storeLampiranAngkaKredit(this.items).then(response => {
					let res = response.data
					if (res.success){
						store.commit('snackbar/setSnack',{message: res.message, color:'success'})
						this.$emit('onClose',false)
						this.dialogFormAssignmentSKP = false
						// setTimeout(()=>{
						// 	window.location.reload()
						// },2000)
						this.$emit('onTableChange',this.itemsTahunan) //melempar kembali hasil get data ke SKPTahunan.vue dengan method name onTableChange
					} else {
						store.commit('snackbar/setSnack',{message: res.message, color:'error'})
					}
        })
			},
			updateTableTahunan(val){
				this.forceUpdate = false
				this.tableValueTahunan = val ? val: this.tableValueTahunan
				this.loadingTahunan = true
				this.itemsTahunan = {}

				SKPService.getSKP({
					search:this.tableValueTahunan.search,
					row_per_page:this.tableValueTahunan.itemsPerPage,
					triwulan:"tahun",
					page:this.tableValueTahunan.page})
				.then((response)=>{
					// console.log('tahunan',response.data)
					this.itemsTahunan = response.data
					for (let i = 0; i < response.data.data.length; i++) {
						const element = response.data.data[i];
						this.eselon = element.eselon
						this.jft = element.jft
						break
					}
					this.$emit('onTableChange',this.itemsTahunan) //melempar kembali hasil get data ke SKPTahunan.vue dengan method name onTableChange
					console.log(this.itemsTahunan)
				}).finally(()=>{
					this.loadingTahunan = false
				})
			},
    },
  }
</script>
